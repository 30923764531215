import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import * as firebase from "firebase";
import "firebase/compat/storage";
import 'firebase/compat/auth';

// *** *** ***
// THIS SOURCE FILE IS FOLLOWING THE DOCUMENTATION AVAILABLE ON:
// https://vuefire.vuejs.org/vuefire/getting-started.html#plugin
// *** *** ***
var fb;

if (!firebase.apps.length) {
   fb = firebase.initializeApp({
      // apiKey: process.env.VUE_APP_API_KEY,
      // authDomain: process.env.VUE_APP_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_DATABASE_URL,
      projectId: process.env.VUE_APP_PROJECT_ID,
      storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
   });

   // fb.auth().signInAnonymously()
   //    .then(() => {
   //       // Signed in..
   //       console.log('login')
   //    })
   //    .catch((error) => {
   //       var errorCode = error.code;
   //       var errorMessage = error.message;
   //       console.log(errorCode, errorMessage)
   //    });
} else {
   fb = firebase.app(); // if already initialized, use that one
}

// Get a Firestore instance
export const db = fb.firestore()
export const storage = fb.storage();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const {
   Timestamp,
   GeoPoint
} = firebase.firestore
export {
   Timestamp,
   GeoPoint
}