<template>
  <v-container>
    <v-overlay :absolute="true" :value="isProgress">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="ma-0" justify="center">
      <v-col cols="12" sm="12" md="12" lg="12">
        <form @submit.prevent="onCreateReport">
          <v-card class="mb-4 pa-2" style="height: 200px">
            <div style="height: 100%" id="mapReport"></div>
          </v-card>

          <p>**อย่าลืมเปิด GPS เพื่อหาพิกัดของท่าน และกดปุ่มขวาบนของแผนที่</p>
          <v-col class="pa-0">
            <v-row>
              <v-col>
                <v-text-field
                  name="latitude"
                  label="latitude"
                  id="latitude"
                  v-model="latitude"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  name="longitude"
                  label="longitude"
                  id="longitude"
                  v-model="longitude"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pa-0">
            <v-text-field
              name="address"
              label="น้ำท่วมที่ไหน?"
              placeholder="เช่น หน้าถนนเอกชัย 109, ในซอยอารีย์ 20"
              id="address"
              v-model="address"
              counter="80"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pa-0">
            <v-text-field
              name="description"
              label="ลักษณะการท่วม"
              placeholder="เช่น ท่วมสูง 2-3 ชั่วโมง"
              id="description"
              v-model="description"
              counter="150"
              required
            ></v-text-field>
          </v-col>
          <v-col class="px-0 mt-4">
            <!-- <v-select :items="district_list" label="เลือกเขตที่ต้องการ" solo></v-select> -->
            <v-select
              v-model="district"
              :items="district_list"
              outlined
              required
              label="เลือกเขตที่ต้องการ"
            ></v-select>
          </v-col>
          <v-btn color="orange" dark @click="onPickFile"
            >ส่งภาพประกอบ <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
          >
          <v-col>
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              accept="image/*"
              @change="onFilePicked"
            />
            <p>{{ imageSizeMessage }}</p>
          </v-col>
          <div>
            <img :src="imageUrl" class="img-preview" />
          </div>
          <div class="d-flex justify-end">
            <v-btn color="blue darken-1" v-on:click="onClickCloseButton" text>
              ปิด
            </v-btn>
            <v-btn
              class="primary"
              color="blue darken-1"
              :disabled="!formIsValid"
              type="submit"
            >
              รายงาน
              <v-icon right dark> mdi-cloud-upload </v-icon>
            </v-btn>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { db, GeoPoint, Timestamp, storage } from "@/plugins/firebaseConfig.js";
import district_list from "@/assets/district_list.json";

export default {
  data() {
    return {
      mapReport: null,
      access_token:
        "pk.eyJ1Ijoibml0aWtvcm4iLCJhIjoiY2p6ZHR0Yjk0MDNxNDNncGhqbDk5M3ZpaCJ9.FW231UaLDWmlgt3d7HQ1yg",
      address: "",
      description: "",
      imageUrl: "",
      image: null,
      district_list: district_list,
      latitude: 0,
      longitude: 0,
      district: "คลองสาน",
      date: new Date(),
      time: new Date(),
      isProgress: false,
      imageSizeMessage: "*รองรับภาพขนาดไม่เกิน 10 MBs",
    };
  },
  computed: {
    formIsValid() {
      return this.address !== "" && this.description !== "" && this.imageUrl !== "";
    },
    submittableDateTime() {
      const date = new Date(this.date);
      if (typeof this.time === "string") {
        let hours = this.time.match(/^(\d+)/)[1];
        const minutes = this.time.match(/:(\d+)/)[1];
        date.setHours(hours);
        date.setMinutes(minutes);
      } else {
        date.setHours(this.time.getHours());
        date.setMinutes(this.time.getMinutes());
      }
      return date;
    },
  },
  mounted() {
    const self = this;
    mapboxgl.accessToken = self.access_token;
    self.mapReport = new mapboxgl.Map({
      container: "mapReport", // container ID
      style: "mapbox://styles/mapbox/dark-v10", // style URL
      center: [100.53826, 13.764981], // starting position [lng, lat]
      zoom: 10, // starting zoom
    });
    var geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true,
    });

    self.mapReport.addControl(geolocate);
    setTimeout(() => {
      self.mapReport.resize();
    }, 1000);

    self.mapReport.resize();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords.latitude, position.coords.longitude);
      });
    } else {
      /* geolocation IS NOT available, handle it */
    }

    geolocate.on("geolocate", function (e) {
      var lon = e.coords.longitude;
      var lat = e.coords.latitude;
      self.latitude = lat;
      self.longitude = lon;
    });
  },
  methods: {
    onClickCloseButton() {
      const self = this;
      self.$emit("childToParent", false);
      self.address = "";
      self.description = "";
      self.imageUrl = "";
      self.image = null;
    },
    onCreateReport() {
      const self = this;
      if (!this.formIsValid) {
        return;
      }
      if (!this.image) {
        return;
      }
      this.isProgress = true;
      const meetupData = {
        description: this.description,
        address: this.address,
        createdate: Timestamp.fromDate(new Date()),
        district: this.district,
        title: "น้ำท่วม",
        validate: false,
        coordinates: new GeoPoint(self.latitude, self.longitude),
      };
      let key;

      db.collection("report")
        .add(meetupData)
        .then((docRef) => {
          key = docRef.id;
          return key;
        })
        .then((key) => {
          const filename = this.image.name;
          const ext = filename.slice(filename.lastIndexOf("."));
          return storage
            .ref("report/" + key + ext)
            .put(this.image, { contentType: "image/jpeg" });
        })
        .then((fileData) => {
          return storage.ref(fileData.metadata.fullPath).getDownloadURL();
        })
        .then((imageUrl) => {
          meetupData.imageUrl = imageUrl;
          db.collection("report")
            .doc(key)
            .set(meetupData)
            .then(() => {
              this.isProgress = false;
              console.log("user updated!");
              self.address = "";
              self.description = "";
              self.imageUrl = "";
              self.image = null;
              self.$emit("childToParent", false);
              self.$emit("isSuccess", true);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file!");
      }
      const fileReader = new FileReader();

      if (files[0].size <= 10000000) {
        fileReader.addEventListener("load", () => {
          this.imageUrl = fileReader.result;
        });
        fileReader.readAsDataURL(files[0]);
        this.image = files[0];
      } else {
        alert("ขนาดภาพใหญ่กว่า 10 MBs กรุณาลดขนาดของภาพ");
        this.imageUrl = "";
        this.image = "";
      }
    },
  },
};
</script>

<style scoped>
.img-preview {
  max-height: 150px;
}
</style>
