<template>
  <div class="main-page">
    <!-- dialog -->
    <v-row class="report-btn">
      <v-dialog v-model="dialog" persistent max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-large color="#f47932" dark v-bind="attrs" v-on="on">
            <v-icon left dark> mdi-waves </v-icon>
            รายงานน้ำท่วม
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <div>รายงานน้ำท่วม</div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <Createform
                v-on:childToParent="onCloseButton"
                v-on:isSuccess="checkStatus"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- display content -->
    <div id="area" class="elevation-4">
      <div id="results">
        <v-card
          class="d-flex flex-row pa-4 ma-2"
          v-for="list in items"
          :key="list.imageUrl"
          @click="gotoLocation(list)"
        >
          <div class="flex-grow-2">
            <v-img
              class="rounded-lg"
              height="100"
              width="100"
              :src="list.imageUrl"
            ></v-img>
          </div>
          <div class="content-report pl-4 flex-grow-4">
            <h3>น้ำท่วมที่ {{ list.address }}</h3>
            <div class="address">{{ list.description }}</div>
            <div style="display: flex; justify-content: space-between; margin-top: 18px">
              <v-chip class="" color="#0c3455" text-color="white" small>
                เขต{{ list.district }}
              </v-chip>
              <div class="date">
                {{ convertTimestampToDate(list.createdate) }}
              </div>
            </div>
          </div>
        </v-card>
      </div>

      <div class="disclaimer px-2">
        หมายเหตุ: เป็นการรวบรวมข้อมูลพื้นที่น้ำท่วม
        สำหรับใช้วิเคราะห์ปัญหาการแก้ไขในอนาคตแบบองค์รวม
      </div>
    </div>
    <div id="map"></div>
    <div class="legend-map">
      <v-card>
        <v-card-text>
          <h2>เลือกแสดงผลข้อมูล</h2>
          <v-checkbox
            v-model="isPumping"
            label="ประตู/สถานีสูบน้ำ"
            color="orange darken-3"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="isTunnel"
            label="อุโมงค์ระบายน้ำ"
            color="orange darken-3"
            hide-details
          ></v-checkbox>
          <!-- <v-checkbox
            v-model="isFloodLocation"
            label="พิกัดน้ำท่วม"
            color="green darken-3"
            hide-details
          ></v-checkbox> -->
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      :timeout="alertTimeout"
      v-model="alert"
      bottom
      dark
      fixed
      :color="alertColor"
      right
      :icon="alertIcon"
    >
      <v-icon dark right>
        {{ alertIcon }}
      </v-icon>
      {{ alertText }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, Timestamp } from "@/plugins/firebaseConfig.js";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import mapboxgl from "mapbox-gl";
import damImg from "@/assets/dam_24px.png";
import Createform from "@/components/Createform.vue";
import drainage_station from "@/assets/drainage_station.json";
import drainage_tunnel from "@/assets/drainage_tunnel.json";

Vue.use(VueAxios, axios);

export default {
  name: "BangkokMap",
  components: {
    Createform,
  },
  data: () => ({
    alert: false,
    alertText: "นำเข้าข้อมูลของท่านสำเร็จ โปรดรอการตรวจสอบ",
    alertIcon: "mdi-checkbox-marked-circle",
    alertColor: "success",
    alertTimeout: 4000,
    map: null,
    access_token:
      "pk.eyJ1Ijoibml0aWtvcm4iLCJhIjoiY2p6ZHR0Yjk0MDNxNDNncGhqbDk5M3ZpaCJ9.FW231UaLDWmlgt3d7HQ1yg",
    items: [],
    drainage_station: drainage_station,
    drainage_tunnel: drainage_tunnel,
    damImg: damImg,
    drainage_station_layer: null,
    drainage_tunnel_layer: null,
    dialog: false,
    isPumping: true,
    isTunnel: true,
    isFloodLocation: true,
  }),
  methods: {
    getData() {
      const self = this;
      db.collection("report")
        .where("validate", "==", true)
        .orderBy("createdate", "desc")
        .limit(8)
        .get()
        .then((querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => doc.data());
          // do something with documents
          self.items = documents;
          self.drawMarker(documents);
        });
    },
    init() {
      const self = this;
      mapboxgl.accessToken = self.access_token;
      self.map = new mapboxgl.Map({
        container: "map", // container ID
        style: "mapbox://styles/mapbox/dark-v10", // style URL
        center: [100.53826, 13.764981], // starting position [lng, lat]
        zoom: 10, // starting zoom
        pitch: 60,
      });
      // self.drawDrainageStation();
      self.map.loadImage(damImg, (error, image) => {
        if (error) throw error;

        // Add the image to the map style.
        self.map.addImage("dam", image);
      });

      self.map.on("load", () => {
        self.map.addSource("drainage-station", {
          type: "geojson",
          data: self.drainage_station,
        });
        self.map.addSource("drainage-tunnel", {
          type: "geojson",
          data: self.drainage_tunnel,
        });
        self.map.addLayer({
          id: "drainage-tunnel",
          type: "line",
          source: "drainage-tunnel",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#187bcd",
            "line-width": 2,
          },
        });

        self.map.addLayer({
          id: "drainage-station",
          type: "symbol",
          source: "drainage-station", // reference the data source
          layout: {
            "icon-image": "dam", // reference the image
            "icon-size": 0.5,
          },
        });
      });

      self.map.on("click", "drainage-station", (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.station_na;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(self.map);
      });
      self.map.on("mouseenter", "drainage-station", () => {
        self.map.getCanvas().style.cursor = "pointer";
      });
    },
    gotoLocation(list) {
      const self = this;
      if (list.coordinates._long === 0) {
        alert("ผู้ใช้ไม่ได้ระบุพิกัด");
      } else {
        self.map.flyTo({
          zoom: 17,
          center: [list.coordinates._long, list.coordinates._lat],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }
    },
    drawMarker(data) {
      const self = this;

      data.forEach((element) => {
        // create the popup
        const popup = new mapboxgl.Popup({ offset: 25 }).setText(
          element.title + " " + element.address
        );

        // create DOM element for the marker
        const el = document.createElement("div");
        el.className = "marker";
        el.style.backgroundImage = "url(" + element.imageUrl + ")";

        // create the marker
        new mapboxgl.Marker(el)
          .setLngLat([element.coordinates._long, element.coordinates._lat])
          .setPopup(popup) // sets a popup on this marker
          .addTo(self.map);
      });
    },
    drawDrainageStation() {
      const self = this;
      self.map.addLayer({
        id: "drainage-station",
        type: "symbol",
        source: "drainage-station", // reference the data source
        layout: {
          "icon-image": "dam", // reference the image
          "icon-size": 0.5,
        },
      });
    },
    drawDrainageTunnel() {
      const self = this;
      self.map.addLayer({
        id: "drainage-tunnel",
        type: "line",
        source: "drainage-tunnel",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#187bcd",
          "line-width": 2,
        },
      });
    },
    removeOverlay(id) {
      const self = this;
      self.map.removeLayer(id);
    },
    onCloseButton(value) {
      const self = this;
      self.dialog = value;
    },
    checkStatus(value) {
      const self = this;
      self.alert = value;
    },
    convertTimestampToDate(ts) {
      let date = new Timestamp(ts.seconds, ts.nanoseconds).toDate();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let fecha = day + "/" + month + "/" + year;
      return fecha;
    },
  },
  compute: {},
  mounted() {
    const self = this;
    self.init();
    self.getData();
  },
  watch: {
    isPumping(val) {
      const self = this;
      if (val) {
        self.drawDrainageStation();
      } else {
        self.removeOverlay("drainage-station");
      }
    },
    isTunnel(val) {
      const self = this;
      if (val) {
        self.drawDrainageTunnel();
      } else {
        self.removeOverlay("drainage-tunnel");
      }
    },
  },
};
</script>

<style>
html {
  overflow-y: unset !important;
}
h2,
p {
  font-family: "Prompt", Times, serif;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
/* .v-application .elevation-4 {
  z-index: 3;
} */
.v-main {
  height: calc(100% - 64px);
}
.report-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.main-page {
  font-family: "Prompt", Courier, monospace;
  display: flex;
  flex-direction: row;
  height: calc(100%);
}
#area {
  flex: 2;
  height: 100%;
  overflow-y: auto;
}
#map {
  height: 100%;
  flex: 4;
}
#results {
  height: calc(100% - 40px);
  overflow-y: auto;
}
.report {
  position: absolute !important;
  bottom: 10px;
  /* left: 500px; */
}
.date {
  font-size: 13px;
}
.title-web {
  color: #0c3455;
}
.description-web {
  color: #686c70;
}
.topic {
  font-weight: 600;
}
.address {
  color: rgb(84, 84, 84);
  font-size: 14px;
}
.content-report {
  width: 100%;
}
.legend-map {
  position: absolute;
  bottom: 30px;
  right: 10px;
  z-index: 10;
}
.marker {
  background-size: cover;
  width: 42px;
  height: 42px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}
.disclaimer {
  font-size: 12px;
  height: 40px;
}

@media only screen and (max-width: 767px) {
  .main-page {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  #area {
    flex: 1 !important;
    height: 50% !important;
  }
  #map {
    flex: 1 !important;
    height: 50% !important;
  }
  #results {
    /* height: 100% !important; */
    height: calc(100% - 40px);
  }
  .report-btn {
    bottom: 50%;
  }
  .legend-map {
    bottom: 65%;
    font-size: 18px;
    display: none;
  }
}
</style>
