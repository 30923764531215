<template>
  <v-app>
    <v-app-bar app color="white" dark>
      <div class="d-flex align-center">
        <div>
          <v-img
            alt=""
            class="shrink mr-2"
            contain
            :src="mfp_logo"
            transition="scale-transition"
            height="40"
            width="80"
          />
        </div>
        <div>
          <h2 class="title-web">กรุงเทพก้าวไกล</h2>
          <div class="description-web">ระบบรวบรวมข้อมูลน้ำท่วม แบบประชาชนมีส่วนร่วม</div>
        </div>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import mfp_logo from "@/assets/Move_Forward_Party_Logo.svg";

Vue.use(VueAxios, axios);

export default {
  name: "BangkokMap",
  components: {
    // Home,
  },
  data: () => ({
    mfp_logo: mfp_logo,
  }),
};
</script>

<style>
html {
  overflow-y: unset !important;
}
html, body, .v-application, .v-application--wrap  {
  height: 100% !important;
}
h2,
p {
  font-family: "Prompt", Times, serif !important;
}
.v-application {
  font-family: "Prompt", sans-serif !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
.v-application .elevation-4 {
  z-index: 3;
}
.main-page {
  font-family: "Prompt", Courier, monospace;
  display: flex;
  flex-direction: row;
  height: calc(100%);
}
.report {
  position: absolute !important;
  bottom: 10px;
  /* left: 500px; */
}
.date {
  font-size: 13px;
}
.title-web {
  color: #0c3455;
}
.description-web {
  color: #686c70;
  font-size: 12px;
}
.topic {
  font-weight: 600;
}
.address-place {
  color: rgb(84, 84, 84);
  font-size: 14px;
}
.address {
  color: #5b5b5b;
}
.content-report {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .main-page {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .title-web {
    font-size: 20px;
  }
  #area {
    flex: 1 !important;
    height: 70% !important;
  }
  #map {
    flex: 1 !important;
    height: 30% !important;
  }
  #results {
    height: 100% !important;
    /* overflow-x: scroll; */
  }
}
</style>
